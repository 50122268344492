import _ from "lodash-contrib";
import moment from "moment/moment";

const Component = {
  /**
   * It converts "daytimeRuleConfig" to "Daytime rule config"
   */
  camelCaseToReadable: function (input) {
    let str = input;
    if (_.isArray(input)) {
      str = input.map((i) => _.upperFirst(i)).join(" - "); // ["daytime", "rule"] -> "DaytimeRule"
    }
    str = str.charAt(0).toUpperCase() + str.slice(1); // "daytimeRule" -> "DaytimeRule"
    str = str.replace(/([A-Z])/g, " $1"); // "DaytimeRule" -> " Daytime Rule";
    str = str.slice(1); // " Daytime Rule" -> "Daytime Rule"
    str = str.toLowerCase();
    str = str.charAt(0).toUpperCase() + str.slice(1); // "daytime rule" -> "Daytime rule";
    return str;
  },
  isoDateToReadable: function (isoDateStr) {
    const date = new Date(isoDateStr);
    return date.toLocaleString();
  },
  arrayToCsv: function (array, isToUpperCase) {
    return isToUpperCase ? array.toString().toUpperCase() : array.toString();
  },
  csvToStringArray: function (csvString) {
    return csvString ? csvString.split(",") : [];
  },
  csvToNumberArray: function (csvString) {
    return csvString ? csvString.split(",").map((str) => Number(str)) : [];
  },
  mapIsoOffsetDateTimeStringToHoursRemaining: function (
    isoOffsetDateTimeString,
  ) {
    if (!isoOffsetDateTimeString) {
      return "";
    }
    const now = Date.now();
    console.log(now);
    let date = new Date(isoOffsetDateTimeString);
    console.log(date);
    const diffMillis = date - now;
    return Math.round((diffMillis / 1000 / 3600) * 10) / 10;
  },
  mapHoursRemainingToIsoOffsetDateTimeString: function (hoursRemaining) {
    const now = new Date();
    now.setTime(now.getTime() + hoursRemaining * 3600 * 1000);
    return now.toISOString();
  },
  mapIsoDatetimeToUtcLocalDatetime: function (isoDatetime) {
    if (!isoDatetime) {
      return null;
    }
    const isManualEditInProgress = moment(isoDatetime).isBefore(
      "2000-01-01T00:00:00",
    ); // User is typing date
    if (isManualEditInProgress) {
      return isoDatetime;
    }
    const isOffsetDatetime =
      isoDatetime.includes("Z") || isoDatetime.includes("+");
    const isUtcLocalTime = !isOffsetDatetime; // Entire PABLO uses UTC, and so when there is no offset then it must be UTC
    let result = "";
    if (isOffsetDatetime) {
      result = moment(isoDatetime).utc(false).format("YYYY-MM-DDTHH:mm:ss");
    } else if (isUtcLocalTime) {
      result = moment(isoDatetime + "Z").format("YYYY-MM-DDTHH:mm:ss");
    }
    console.log(isoDatetime, result);
    return result;
  },
  convertJsonArrayToCsv: function (jsons) {
    const items = jsons;
    const header = Object.keys(items[0]);
    const csv = [
      header.join(","), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) =>
            row[fieldName] !== null ? JSON.stringify(row[fieldName]) : null,
          )
          .join(","),
      ),
    ].join("\r\n");
    return csv;
  },
  convertJsonArrayToDownloadableCsvFileUrl: function (jsons) {
    const csv = this.convertJsonArrayToCsv(jsons);
    const csvBlob = new Blob([csv], {
      type: "text/csv",
    });
    return window.URL.createObjectURL(csvBlob);
  },
  sleep: function (ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
  localeCompare(a, b) {
    a = a || "";
    b = b || "";
    return a.localeCompare(b);
  },
  getUrlParameterValue(paramName) {
    let url = window.location.href;
    const isHashUrl = url.includes("/#/");
    if (isHashUrl) {
      url = url.split("/#/")[1];
    }
    const params = new URLSearchParams(url);
    return params.get(paramName);
  },
  debounce(func, delayMs, timeoutReactUseRef) {
    clearTimeout(timeoutReactUseRef.current);
    timeoutReactUseRef.current = setTimeout(() => {
      func();
      timeoutReactUseRef.current = null;
    }, delayMs);
  },
};

export default Component;
